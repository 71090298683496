jQuery(function() {
  var real_interval = 10000;
  var meaningful_interval = 60000;
  var counter = 0;

  setInterval(function checkSession() {
    //effectively fires once a minute.  Done this way to stop the Chrome violation messages.
    if( counter % meaningful_interval === 0 ){
      Pace.ignore(function(){
        jQuery.get('/check-session', function(data) {
          // if session was expired
          if (data.guest && window.location.href.indexOf("login") <= 0) {
            location.reload();
          }
        });
      });
    }
    
    counter += real_interval;

  }, real_interval); // every 10 seconds, formerly minute.
});
