//polyfill for string includes
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    } 
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

//...for array includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        
        k++;
      }

      return false;
    }
  });
}

//Initialize tooltips
jQuery('body').tooltip({selector: '.has-tooltip'});

//Copy to clipboard
jQuery(document).ready(function() {
  var clipboard = new Clipboard('.mt-clipboard');

});

//Change copy tooltip text on click
jQuery('body').on('click', '.mt-clipboard', function(event) {
  event.preventDefault();
  //Set all other tooltips to original-title to "Copy"
  jQuery('body .mt-clipboard').each(function(index, el) {
    jQuery(this).attr('data-original-title', 'Copy');
  });
  //Change specific tooltip text to "Copied"
  jQuery(this).tooltip()
    .attr('data-original-title', 'Copied!')
    .tooltip('show');
});

// This is a IIFE function which returns an object of util functions. These functions are globally available to any app via $Util
var $Util = function(){
    console.log("Utility functions loading......");
    return {
      //======================
      // validate macs
      //======================
     isValidMacFormat: function(mac_addresses, action) {
      var regex_format  = /^(([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([a-fA-F0-9]{4}[:]){2}([a-fA-F0-9]{4})|([a-fA-F0-9]{4}[.]){2}([a-fA-F0-9]{4})|([a-fA-F0-9])|([a-fA-F0-9:]{17}|[a-fA-F0-9]{12}))?$/
    
      var error_macs = [];
      var mac_addresses_split  = mac_addresses.split("\n");
    
      for(var i = 0; i < mac_addresses_split.length;i++) {
        if (!regex_format.test(mac_addresses_split[i])) {
          error_macs.push(mac_addresses_split[i]);
        }
      }
    
      if (error_macs.length > 0) {
        toastr["error"]("There was an error "+action+" those modems. Invalid Mac provided " + error_macs.toLocaleString() + "  .Please try again later.", "Error", {timeOut: 0,extendedTimeOut:0});
        return false;
      }
      
        return true;

    },
    isValidMacFormat2: function(mac_addresses, action) {
      var regex_format  = /^(([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([a-fA-F0-9]{4}[:]){2}([a-fA-F0-9]{4})|([a-fA-F0-9]{4}[.]){2}([a-fA-F0-9]{4})|([a-fA-F0-9])|([a-fA-F0-9:]{17}|[a-fA-F0-9]{12}))?$/
      var mac;
      var error_macs = [];
      var mac_addresses_split  = mac_addresses.split("\n");
    
      for(var i = 0; i < mac_addresses_split.length;i++) {
         var n = mac_addresses_split[i].indexOf("|");
         if (n<0){
          mac = mac_addresses_split[i];
         }
         else {
          mac = mac_addresses_split[i].substring(0, n);
         }
         
        if (!regex_format.test(mac)) {
          error_macs.push(mac);
        }
      }
    
      if (error_macs.length > 0) {
        toastr["error"]("There was an error "+action+" those modems. Invalid Mac provided " + error_macs.toLocaleString() + "  .Please try again later.", "Error", {timeOut: 0,extendedTimeOut:0});
        return false;
      }
      
        return true;

    },

    isValidEmailAddress: function(emailAddress) {
      if(emailAddress != null || emailAddress != undefined || emailAddress != ""){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
      }else{
        return false;
      }
    },

    formatMAC: function(e) {
      var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.target.value.replace(/[^a-f0-9]/ig, "");

      while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
      }

      e.target.value = str.slice(0, 17);
    },

    //Formats a MAC, returns a string
    formatMACSearch: function(e) {
      //console.log(esapi.sanitize(e));
      var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
          str = e.replace(/[^a-f0-9]/ig, "");

      while (r.test(str)) {
          str = str.replace(r, '$1' + ':' + '$2');
      }

      return str;
    },

    //Formats a MAC, returns a string of correct size.
    formatMACSearchTrim: function(e) {
      //console.log(esapi.sanitize(e));
          var r = /([a-f0-9]{2})([a-f0-9]{2})/i,
              str = e.replace(/[^a-f0-9]/ig, "");

          while (r.test(str)) {
              str = str.replace(r, '$1' + ':' + '$2');
          }

          str = str.slice(0,17);
        
      return str;
    },

    emailLocalValid: function(emailLocal) {
      var emailLocalLowerCase = emailLocal.toLowerCase();
      if (
        // length must be less than or equal to 64 chars
        emailLocalLowerCase.length <= 64 &&
        // must contain only allowed chars
        /^[a-z0-9_\.\-]+$/.test(emailLocalLowerCase) &&
        // must not begin or end in '.'
        /^[^\.]/.test(emailLocalLowerCase) &&
        /[^\.]$/.test(emailLocalLowerCase) &&
        // must not begin or end in '-'
        /^[^\-]/.test(emailLocalLowerCase) &&
        /[^\-]$/.test(emailLocalLowerCase) &&
        // must not begin or end in '_'
        /^[^_]/.test(emailLocalLowerCase) &&
        /[^_]$/.test(emailLocalLowerCase) &&
        // must not contain double dots '..' or double hyphens '--' or double underscores '__'
        /^((?!\.\.).)*$/.test(emailLocalLowerCase) &&
        /^((?!\-\-).)*$/.test(emailLocalLowerCase) &&
        /^((?!__).)*$/.test(emailLocalLowerCase) &&
        // must not contain dot hyphen '.-' or dot underscore '._' or hyphen dot '-.' or hyphen underscore '-_' or underscore dot '_.' or underscore hyphen '_-'
        /^((?!\.\-).)*$/.test(emailLocalLowerCase) &&
        /^((?!\._).)*$/.test(emailLocalLowerCase) &&
        /^((?!\-\.).)*$/.test(emailLocalLowerCase) &&
        /^((?!\-_).)*$/.test(emailLocalLowerCase) &&
        /^((?!_\.).)*$/.test(emailLocalLowerCase) &&
        /^((?!_\-).)*$/.test(emailLocalLowerCase)
      ) {
        return true;
      } else {
        return false;
      }
    },

    emailDomainValid: function(emailDomain) {
      var emailDomainLowerCase = emailDomain.toLowerCase();
      var emailTLD = emailDomainLowerCase.substring(emailDomainLowerCase.lastIndexOf('.') + 1);
      if (
        // domain must contain only allowed chars
        /^[a-z0-9\.\-]+$/.test(emailDomainLowerCase) &&
        // domain must not begin or end in '.'
        /^[^\.]/.test(emailDomainLowerCase) &&
        /[^\.]$/.test(emailDomainLowerCase) &&
        // domain must not begin or end in '-'
        /^[^\-]/.test(emailDomainLowerCase) &&
        /[^\-]$/.test(emailDomainLowerCase) &&
        // domain must not contain double dots '..' or double hyphens '--'
        /^((?!\.\.).)*$/.test(emailDomainLowerCase) &&
        /^((?!\-\-).)*$/.test(emailDomainLowerCase) &&
        // domain must not contain dot hyphen '.-' or hyphen dot '-.'
        /^((?!\.\-).)*$/.test(emailDomainLowerCase) &&
        /^((?!\-\.).)*$/.test(emailDomainLowerCase) &&
        // TLD must not contain digits or hyphens '-'
        /^((?![0-9]).)*$/.test(emailTLD) &&
        /^((?!\-).)*$/.test(emailTLD)
      ) {
        return true;
      } else {
        return false;
      }
    },

    emailValid: function(email) {
      var emailLowerCase = email.toLowerCase();
      var emailLocal = emailLowerCase.substring(0, emailLowerCase.indexOf('@'));
      var emailDomain = emailLowerCase.substring(emailLowerCase.indexOf('@') + 1);
      if (
        // length must be less than or equal to 254 chars
        emailLowerCase.length <= 254 &&
        // local part must be valid
        this.emailLocalValid(emailLocal) &&
        // domain part must be valid
        this.emailDomainValid(emailDomain)
      ) {
        return true;
      } else {
        return false;
      }
    },

    BYOIDEmailValid: function(email) {
      var emailLowerCase = email.toLowerCase();
      var emailLocal = emailLowerCase.substring(0, emailLowerCase.indexOf('@'));
      var emailDomain = emailLowerCase.substring(emailLowerCase.indexOf('@') + 1);
      
      if(emailLocal == null || emailDomain == null || emailLocal == "" || emailDomain == ""){
        return false;
      }
      
      if (
        // length must be less than or equal to 254 chars
        emailLowerCase.length <= 254 &&
        // domain part must be valid
        this.emailDomainValid(emailDomain)
      ) {
        return true;
      } else {
        return false;
      }
    },

    GenericEmailValid: function(email) {
      var emailLowerCase = email.toLowerCase();
      var emailLocal = emailLowerCase.substring(0, emailLowerCase.indexOf('@'));
      var emailDomain = emailLowerCase.substring(emailLowerCase.indexOf('@') + 1);
      
      if(emailLocal == null || emailDomain == null || emailLocal == "" || emailDomain == ""){
        return false;
      }
      
      if (
        // length must be less than or equal to 254 chars
        emailLowerCase.length <= 254 &&
        // domain part must be valid
        this.emailDomainValid(emailDomain)
      ) {
        return true;
      } else {
        return false;
      }
    },

    isValidEmailAddress: function(emailAddress) {
        var pattern = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.{0,1}([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){1,254}([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]){1,254}([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.*$/i;
        if(emailAddress.length > 0 ){
          if(this.emailUsername(emailAddress).length <= 64 && pattern.test(emailAddress)){
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }
    },

    emailUsername: function(emailAddress) {
      //console.log("Hit emailUsername!");
         return emailAddress.match(/^(.+)@/)[1];
      },

      isValidPostalCode: function(postalCode, countryCode) {
      var postalCodeRegex;
        switch (countryCode) {
            case "US":
                postalCodeRegex = /\d{5}([ \-]\d{4})?/;
                break;
            case "CA":
                postalCodeRegex = /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/;
                break;
            default:
                postalCodeRegex = /\d{5}([ \-]\d{4})?/;
        }
        return postalCodeRegex.test(postalCode);
    },

    checkPasswordStandard : function(string){
      /*[*@!#$%&()^~{}]+*/
      /*New standard-%$#@!*/
       //var checkSpecial = /[-!$%^&*()_+|~=`\\#{}\[\]:";'<>?,.\/@]/.test(string),
       //var checkSpecial = /[%!$#@]/.test(string),
      var checkSpecial = /[-!"#$%&'()*+,.\/:;<=>?@[\\\]\^_`{|}~]/.test(string),
        checkUpper = /[A-Z]+/.test(string),
        checkLower = /[a-z]+/.test(string),
        test = false;


        if (checkUpper && checkLower && checkSpecial && string.length >= 8 && string.length <= 12 && string.indexOf(' ') == -1){
          test = true;
        }else{
          test = false;
        }
    return test;
    },

    checkPasswordSpecialCharacters: function(string){
      var test = false;
      //var checkSpecial = /[%!$#@]/.test(string);
      var checkSpecial = /[-!$%^&*()_+|~=`\\#{}\[\]:";'<>?,.\/@]/.test(string);
      if(checkSpecial){
        test = true;
      }
      return test;
    },

    checkPasswordUpperCharacters: function(string){
      var test = false;
      var checkUpper = /[A-Z]+/.test(string);
      if(checkUpper){
        test = true;
      }
      return test;
    },

    checkPasswordLowerCharacters: function(string){
      var test = false;
      var checkLower = /[a-z]+/.test(string);
      if(checkLower){
        test = true;
      }
      return test;
    },
    
    checkPasswordLength: function(string){
      var test = false;
      if(string.length >= 8 && string.length <= 12){
        test = true;
      }
      return test;
    },
    
    checkPasswordSpace: function(string){
      var test= false;

      if(string.indexOf(' ') > -1){
        test=true;
      }
      
      return test;
    },
    
    isValidPostalCode: function(postalCode) {
        var postalCodeRegex = /\d{5}([ \-]\d{4})?/;
        return postalCodeRegex.test(postalCode);
    },
    
    numbersOnly: function(string){
      var pattern = new RegExp(/[^0-9]/g);
      return pattern.test(string);
    },

    lettersOnly: function(string){
      var pattern = new RegExp(/^[a-zA-Z]+$/);
      return pattern.test(string);
    },

    //This method will check if an object is empty, e.g. checking a JSON object for children nodes.
    isEmpty: function(ob){
       for(var i in ob){ return false;}
      return true;
    },

    //A 'better' version of the above, soley for checking if the object it was constructed from had properties defined in it's prototype object.
    isEmptyPrototypeCheck: function(o) {
      var o = {};
      for(var p in o) {
        if (o[p] != o.constructor.prototype[p])
          return false;
      }
      return true;
    },

    /*
    grants a means to do something for when AFTER targeted element first exists.
    */
    waitForElement: function(selector) {
        return new Promise(function(resolve, reject) {
            var element = document.querySelector(selector);

            if (element) {
                resolve(element);
                return;
            }

            var observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    var nodes = Array.from(mutation.addedNodes);
                    for (var node in nodes) {
                        if (node.matches && node.matches(selector)) {
                            observer.disconnect();
                            resolve(node);
                            return;
                        }
                    };
                });
            });

            observer.observe(document.documentElement, {
                childList: true,
                subtree: true
            });
        });
    },

    /**
    * PURPOSE: for turning property names which look like 'camelCaseProp' to 
    * make them into 'camel_case_prop' (snake case property names).
    *
    * @param {object} src - source object with the camel case property names.
    *
    * @return {object} - the new / revised object with snake case property names.
    */
    camel2SnakeProps( src={} ){
      let dest = {};
    
      for( let i in src ){
        let j = i.replace(/\.?([A-Z]+)/g, (x,y) =>  "_" + y.toLowerCase()).replace(/^_/, "");
        dest[j] =  src[i];
      }
    
      return dest;
    },


    /**
    * PURPOSE: great for getting the current function name, like how PHP's 
    * '__FUNCTION__' magic constant works, or more accurately, returns.
    *
    * @param {boolean} fullname - whether to keep the whole name of the function, like 
    * 'someElement.myFunc', or just the immediate (and most commonly expected)
    * variant - 'myFunc'. Default = false
    *
    * @return {string} - the function name.
    *
    * @example
    *
    *   function simpleTestFunction(){
    *     var f_name = $Util.funcName();
    * 
    *     console.log(esapi.sanitize( f_name )); // expected: "simpleTestFunction"
    *   }
    */
    funcName(fullname=false){
      let f = (new Error()).stack.match(/at (\S+)/g)[1].slice(3);

      if(!fullname === true){
        f = f.replace( /^(\w+\.)+/, "" );
      }

      return f;
    },
    
    //==========================
    //   Test
    //==========================
    test1: function() {
      console.log(esapi.sanitize(this));
      return {
          name:"test1"
      }
    }
  }
  console.log("Utility functions loaded......");
}()
  
Base.esapi.properties.application.Name = "Tech Maestro";  //
org.owasp.esapi.ESAPI.initialize();                       // Dean added for JavaScript console log sanitizing
globalThis.esapi = $ESAPI.encoder();    				  // 
